import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import FilterHeader from 'components/shared/table/FilterHeader';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import './SubmittalLogTable.css';
import Date from 'components/shared/text/Date';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import BaseStatus from 'components/shared/text/BaseStatus';
import {
  SubmittalStatusEnum,
  submittalStatusLabel,
} from 'domain/submittal-status-enum';
import SubmittalPermissions from 'permissions/submittal-permissions';
import Submittal from 'domain/submittal';
import Rate from 'components/shared/rate/Rate';
import { getDarkMode } from 'selectors/theme';

class SubmittalLogTable extends Component {
  static propTypes = {
    submittals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    tradeCodes: PropTypes.arrayOf(PropTypes.shape({})),
    total: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    currentStatus: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentUser: PropTypes.shape({}).isRequired,
    currentProject: PropTypes.shape({}).isRequired,
    directory: PropTypes.shape({}).isRequired,
    unreadById: PropTypes.shape({}),
    submittalTabSelected: PropTypes.number.isRequired,
    handlePageChange: PropTypes.func.isRequired,
    handleOpenSubmittal: PropTypes.func.isRequired,
    handleReviseSubmittal: PropTypes.func.isRequired,
  };

  addlAssignee = (submittal) => {
    const { currentUser } = this.props;
    return Submittal.addlAssignee(submittal, currentUser);
  };

  dueDate = (submittal) => {
    const addlAssignee = this.addlAssignee(submittal);
    return addlAssignee
      ? addlAssignee.due_date
      : submittal.extended_due_date
      ? submittal.extended_due_date
      : submittal.due_date;
  };

  returnedAt = (submittal) => {
    const addlAssignee = this.addlAssignee(submittal);
    return addlAssignee ? addlAssignee.returned_at : submittal.returned_at;
  };

  handleGenerateLink = (id) => {
    return `/submittals/submittal-log/${id}`;
  };

  renderAssigneeTooltip = (label, status) => {
    return (
      <React.Fragment>
        {label}
        {status && <div>{status}</div>}
      </React.Fragment>
    );
  };

  renderStatus = (submittal) => {
    const {
      assignee_label,
      stamp_title,
      submittal_addl_assignees,
      stamp_action,
    } = submittal;

    let totalItems = 0;
    let currentItems = 0;
    let itemTooltips = [];

    if (submittal_addl_assignees.length) {
      totalItems = submittal_addl_assignees.length + 1;
      currentItems =
        submittal_addl_assignees.filter(
          (addlAssignee) => !!addlAssignee.stamp_action
        ).length + (stamp_action ? 1 : 0);
      itemTooltips = [
        this.renderAssigneeTooltip(assignee_label, stamp_title),
        ...submittal_addl_assignees.map((add) =>
          this.renderAssigneeTooltip(add.assignee_label, add.stamp_title)
        ),
      ];
    }

    return (
      <Rate
        className="submittalStep"
        title={submittalStatusLabel(submittal)}
        titleTooltip={
          assignee_label && this.renderAssigneeTooltip(assignee_label)
        }
        totalItems={totalItems}
        currentItems={currentItems}
        itemTooltips={itemTooltips}
      />
    );
  };

  mapSubmittals = (headers) => {
    const {
      currentUser,
      currentProject,
      directory,
      unreadById,
      handleOpenSubmittal,
      handleReviseSubmittal,
    } = this.props;

    let currentCsiCodeId = null;
    return [].concat(
      ...this.props.submittals.map((submittal, i) => {
        const {
          id,
          csi_code,
          status,
          formatted_number,
          title,
          requested_at,
          submitted_at,
          distributed_at,
          closed,
        } = submittal;

        const link = this.handleGenerateLink(id);
        let row = [];
        if (currentCsiCodeId !== csi_code.id) {
          currentCsiCodeId = csi_code.id;
          row.push(
            <tr key={`csi-${currentCsiCodeId}_${i}`} className="csi-code-row">
              <td>{csi_code.division}</td>
              <td className="csi-code-label" colSpan={headers.length - 1}>
                {csi_code.label}
              </td>
            </tr>
          );
        }

        const dueDate = this.dueDate(submittal);
        const returnedAt = this.returnedAt(submittal);
        const submittalStatusEnum = SubmittalStatusEnum.create(status);
        const draftSubmittal =
          SubmittalStatusEnum.DRAFT.equals(submittalStatusEnum) ||
          SubmittalStatusEnum.DRAFT_PENDING.equals(submittalStatusEnum) ||
          SubmittalStatusEnum.DRAFT_ASSIGNED.equals(submittalStatusEnum);
        const unread = unreadById && !!unreadById[id];

        const assigned = Submittal.isAssignedToUser(currentUser, submittal);
        const responded = Submittal.isUserResponder(currentUser, submittal);
        const canRevise = SubmittalPermissions.canRevise(
          currentUser,
          currentProject,
          directory,
          submittal
        );

        row.push(
          <tr
            key={id}
            className="selectable link-tab"
            onClick={(e) => {
              e.preventDefault();
              handleOpenSubmittal(id);
            }}
          >
            <td>
              <a href={link}>
                {unread && (
                  <BaseStatus
                    labels={[
                      {
                        id: 'unread',
                        label: '',
                      },
                    ]}
                    status="unread"
                  />
                )}
                {formatted_number}
              </a>
            </td>
            <td className="submittal-title">
              <a href={link}>{title || '-'}</a>
            </td>
            <td>
              {/* <a href={link}>
                {received_at ? (
                  <Date value={received_at} format="MM/DD/YYYY" />
                ) : (
                  '-'
                )}
              </a> */}
              {this.ballInCourt(submittal)}
            </td>
            <td>
              <a href={link}>
                {requested_at ? (
                  <Date value={requested_at} format="MM/DD/YYYY" />
                ) : (
                  '-'
                )}
              </a>
            </td>
            <td>
              <a href={link}>
                {submitted_at ? (
                  <Date value={submitted_at} format="MM/DD/YYYY" />
                ) : (
                  '-'
                )}
              </a>
            </td>
            <td>
              <a href={link}>
                {dueDate ? <Date value={dueDate} format="MM/DD/YYYY" /> : '-'}
              </a>
            </td>
            <td>
              <a href={link}>
                {returnedAt ? (
                  <Date value={returnedAt} format="MM/DD/YYYY" />
                ) : (
                  '-'
                )}
              </a>
            </td>
            <td>
              <a href={link}>
                {distributed_at ? (
                  <Date value={distributed_at} format="MM/DD/YYYY" />
                ) : (
                  '-'
                )}
              </a>
            </td>
            <td>
              <a href={link}>
                <div
                  className={
                    draftSubmittal
                      ? 'submittal-status draft'
                      : 'submittal-status'
                  }
                >
                  {this.renderStatus(submittal)}
                </div>
              </a>
            </td>
            <td>
              <div className="action-buttons">
                <Button
                  type={BUTTON_TYPES.LINK}
                  color={BUTTON_COLORS.GREEN}
                  label={assigned && !closed && !responded ? 'Respond' : 'View'}
                  href={link}
                />
                {canRevise && (
                  <Button
                    type={BUTTON_TYPES.LINK}
                    color={BUTTON_COLORS.GREEN}
                    label="Revise"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleReviseSubmittal(id);
                    }}
                  />
                )}
              </div>
            </td>
          </tr>
        );

        return row;
      })
    );
  };

  ballInCourt = (sub) => {
    if (!sub) return '-';
    const {
      assignee_label,
      submittal_addl_assignees,
      closed,
      status,
      returned_at,
      submitted_by,
    } = sub;
    if (closed) return 'N/A';
    const statusWithNa =
      status === 'for_record' ||
      status === 'rejected' ||
      status === 'approved' ||
      status === 'not_reviewed' ||
      status === 'draft' ||
      status === 'draft_assigned' ||
      status === 'draft_pending';
    if (statusWithNa) return 'N/A';
    if (
      !assignee_label &&
      (!submittal_addl_assignees || submittal_addl_assignees.length === 0)
    )
      return '-';
    if (returned_at && !statusWithNa && !closed)
      return submitted_by && submitted_by.label;
    let assignees = [assignee_label];
    const addlAssigneesLabels = submittal_addl_assignees.map(
      (aa) => aa.assignee_label
    );
    assignees = [...assignees, ...addlAssigneesLabels];
    return (
      <React.Fragment>
        {assignees.map((a) => (
          <div>{a}</div>
        ))}
      </React.Fragment>
    );
  };
  render() {
    const {
      currentUser,
      currentProject,
      submittals,
      tradeCodes,
      currentPage,
      currentStatus,
      pageSize,
      total,
      submittalTabSelected,
      handlePageChange,
      darkMode,
      dueDateFilter = 'all',
      tradeCodeFilter = 'all',
    } = this.props;

    const canSeePending = SubmittalPermissions.canViewPending(
      currentUser,
      currentProject
    );
    const canSeeSubmitted = SubmittalPermissions.canViewSubmitted(
      currentUser,
      currentProject
    );
    const canSeeDraft = SubmittalPermissions.canViewDraft(
      currentUser,
      currentProject
    );

    const headers = [
      <FilterHeader
        className="trade-code-header"
        label="COST CODE"
        options={[
          {
            id: 'all',
            label: 'All Cost Codes',
          },
          ...(tradeCodes || [])
            .map((code) => ({
              id: code.id,
              label: `${code.division} ${code.label}`,
            }))
            .sort((code1, code2) => code1.label.localeCompare(code2.label)),
        ]}
        selected={tradeCodeFilter}
        search={true}
        onFilter={(filter) =>
          handlePageChange(1, currentStatus, dueDateFilter, filter)
        }
      />,
      'TITLE',
      'BALL IN COURT',
      'REQUESTED',
      'SUBMITTED',
      <FilterHeader
        label="DUE"
        options={[
          {
            id: 'all',
            label: 'All',
          },
          {
            id: 'pending',
            label: 'Pending',
          },
          {
            id: 'overdue',
            label: 'Overdue',
          },
          {
            id: 'ascending',
            label: 'Ascending',
          },
          {
            id: 'descending',
            label: 'Descending',
          },
        ].filter((st) => !!st)}
        selected={dueDateFilter}
        onFilter={(filter) => {
          handlePageChange(1, currentStatus, filter, tradeCodeFilter);
        }}
      />,
      'RETURNED',
      'DISTRIBUTED',
      submittalTabSelected === 1 ? (
        'STATUS'
      ) : (
        <FilterHeader
          label="STATUS"
          options={[
            canSeeSubmitted && canSeePending
              ? {
                  id: 'not_pre',
                  label: 'All',
                }
              : {
                  id: 'all',
                  label: 'All',
                },
            canSeeDraft && {
              id: 'draft',
              label: 'Draft',
            },
            canSeePending &&
              !canSeeSubmitted && {
                id: 'pre',
                label: 'Pending',
              },
            {
              id: 'assigned_or_draft_assigned',
              label: 'Assigned',
            },
            {
              id: 'cls',
              label: 'Closed',
            },
          ].filter((st) => !!st)}
          selected={currentStatus}
          onFilter={(status) =>
            handlePageChange(1, status, dueDateFilter, tradeCodeFilter)
          }
        />
      ),
      'ACTIONS',
    ];
    return (
      <div className="submittal-log-table-container">
        <Table
          className={`simple-table projects-table submittal-log-table ${
            darkMode ? 'dark-mode' : ''
          }`}
        >
          <colgroup>
            {headers.map((element, index) => {
              return <col key={index + 1} />;
            })}
          </colgroup>

          <thead>
            <tr>
              {headers.map((element, index) => {
                return (
                  <th key={index}>
                    <span>{element}</span>
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {submittals.length === 0 ? (
              <tr>
                <td className="td-empty-table" colSpan={headers.length}>
                  No Submittals
                </td>
              </tr>
            ) : (
              this.mapSubmittals(headers)
            )}
          </tbody>
        </Table>
        <div className="pagination-container">
          <SimplePagination
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
            onPageChange={(page) => {
              handlePageChange(
                page,
                currentStatus,
                dueDateFilter,
                tradeCodeFilter
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    darkMode: getDarkMode(state),
  };
})(SubmittalLogTable);
