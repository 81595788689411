import moment from 'moment';
import { projectScheduleApi } from 'services/api/project-schedule-api';
import { DEFAULT_SCHEDULE_FORM_TAB } from 'domain/schedule';
import { showErrorMessage } from 'actions/messages';
import { downloadFile } from 'services/utils/download-util';
import { setShowEmailModal } from 'components/admin/projects/details/project-email-modal/store/actions';
import {
  PROJECT_SCHEDULE_SET,
  PROJECT_SCHEDULE_SET_FORM_MODAL,
  PROJECT_SCHEDULE_SET_FILTERS,
  PROJECT_SCHEDULE_SET_FORM_TAB,
  PROJECT_SCHEDULE_SET_FORM,
  PROJECT_SCHEDULE_ADD_CATEGORY,
  PROJECT_SCHEDULE_ADD_SCHEDULE,
  PROJECT_SCHEDULE_EDIT_CATEGORY,
  PROJECT_SCHEDULE_REMOVE_CATEGORY,
  PROJECT_SCHEDULE_REMOVE_FROM_CATEGORY,
  PROJECT_SCHEDULE_SET_MESSAGE,
  PROJECT_SCHEDULE_SET_EMAIL_MODAL,
  PROJECT_SCHEDULE_SET_CATEGORY,
  PROJECT_SCHEDULE_SET_PDF_MODAL,
  PROJECT_SCHEDULE_SET_RESOLVED_MODAL,
  PROJECT_SCHEDULE_REMOVE_RESOLVED,
  PROJECT_SCHEDULE_GANTT_INFORMATION_UPDATE,
  UPDATE_GANTT_TASKS,
  UPDATE_GANTT_OPTIONS,
} from './action-types';
import { initialState } from './reducers.data';

/*************************************************
 ****************** REDUX STATE ******************
 ************************************************/

// tables
export const setFiltersProjectSchedule = (payload) => ({
  type: PROJECT_SCHEDULE_SET_FILTERS,
  payload,
});

// form
export const setFormTabProjectSchedules = (payload) => ({
  type: PROJECT_SCHEDULE_SET_FORM_TAB,
  payload,
});
export const setFormProjectSchedules = (payload = {}) => ({
  type: PROJECT_SCHEDULE_SET_FORM,
  payload,
});
const getProjectSchedulesCategoryToForm = (data) => {
  return {
    id: data.id,
    name: data.name,
    visibility: data.visibility,
    visibilityUsers: data.visibility_users,
    editing: data.editing,
    editingUsers: data.editing_users,
    created_by: data.created_by,
    createdAt: data.created_at
      ? moment(data.created_at).format('YYYY-MM-DD HH:mm')
      : null,
  };
};

// modal form
export const setFormModalProjectSchedules = (payload) => ({
  type: PROJECT_SCHEDULE_SET_FORM_MODAL,
  payload,
});
export const toggleFormModalProjectSchedules = (show) => {
  return (dispatch) => {
    if (!show) {
      dispatch(setFormProjectSchedules(initialState.form));
      dispatch(setFormTabProjectSchedules(DEFAULT_SCHEDULE_FORM_TAB));
    }
    dispatch(setFormModalProjectSchedules(show));
  };
};

// message
export const setModalMessageProjectSchedule = (message, type = 'SUCCESS') => ({
  type: PROJECT_SCHEDULE_SET_MESSAGE,
  payload: { message, type },
});

// modal email
export const setModalEmailTypeProjectSchedule = (type) => ({
  type: PROJECT_SCHEDULE_SET_EMAIL_MODAL,
  payload: { type },
});

// pdf modal
export const setModalPdfProjectSchedule = (pdf, type = 'url') => ({
  type: PROJECT_SCHEDULE_SET_PDF_MODAL,
  payload: { pdf, type },
});

// resolved modal
export const setModalResolvedProjectSchedule = (payload) => ({
  type: PROJECT_SCHEDULE_SET_RESOLVED_MODAL,
  payload,
});

// category (rerenders)
export const setProjectSchedules = (payload) => ({
  type: PROJECT_SCHEDULE_SET,
  payload,
});
export const addCategoryProjectSchedules = (payload) => ({
  type: PROJECT_SCHEDULE_ADD_CATEGORY,
  payload,
});
export const removeCategoryProjectSchedules = (payload) => ({
  type: PROJECT_SCHEDULE_REMOVE_CATEGORY,
  payload,
});
export const setCategoryProjectSchedules = (payload) => ({
  type: PROJECT_SCHEDULE_SET_CATEGORY,
  payload,
});
export const removeResolvedProjectSchedule = (payload) => ({
  type: PROJECT_SCHEDULE_REMOVE_RESOLVED,
  payload,
});

// (no rerenders)
export const editCategoryValuesProjectSchedules = (categoryId, category) => ({
  type: PROJECT_SCHEDULE_EDIT_CATEGORY,
  payload: { categoryId, category },
});

// schedules (no rerenders)
export const addProjectSchedule = (categoryId, schedule) => ({
  type: PROJECT_SCHEDULE_ADD_SCHEDULE,
  payload: { categoryId, schedule },
});
export const removeProjectScheduleFromCategory = (categoryId, scheduleId) => ({
  type: PROJECT_SCHEDULE_REMOVE_FROM_CATEGORY,
  payload: { categoryId, scheduleId },
});
export const updateGanttChartData = (schedules) => ({
  type: PROJECT_SCHEDULE_GANTT_INFORMATION_UPDATE,
  payload: schedules,
});

/*************************************************
 ******************* API CALLS *******************
 ************************************************/

// category
export const loadProjectSchedules = (projectId, filters) => {
  return (dispatch) =>
    projectScheduleApi
      .getProjectSchedules(projectId, filters)
      .then((response) => {
        dispatch(setProjectSchedules(response.data));
        dispatch(setFiltersProjectSchedule(filters));
        dispatch(updateGanttChartData(response.data));
      });
};
export const loadProjectSchedulesLog = (projectId, filters) => {
  return (dispatch) =>
    projectScheduleApi
      .getProjectSchedulesLog(projectId, filters)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(setModalPdfProjectSchedule(response.data, 'blob'));
        }
        return response;
      });
};
export const getCategoryProjectSchedules = (
  projectId,
  categoryId,
  options = {}
) => {
  return (dispatch) =>
    projectScheduleApi
      .getProjectSchedulesCategory(projectId, categoryId, options)
      .then((response) => {
        if (!response.ok) dispatch(showErrorMessage(response.errors));
        return response;
      });
};
export const loadCategoryProjectSchedules = (projectId, categoryId) => {
  return (dispatch) => {
    return dispatch(getCategoryProjectSchedules(projectId, categoryId)).then(
      (response) => {
        if (response.ok) {
          dispatch(setCategoryProjectSchedules(response.data));
        }
        return response;
      }
    );
  };
};
export const saveCategoryProjectSchedules = (projectId, form) => {
  return (dispatch) =>
    projectScheduleApi
      .saveCategoryProjectSchedules(projectId, form)
      .then((response) => {
        if (!response.ok) {
          if (response.errors.includes('name already exists')) {
            dispatch(
              setModalMessageProjectSchedule(
                'Schedule name already exists. Please select a new name.',
                'EXCLAMATION'
              )
            );
          } else dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(addCategoryProjectSchedules(response.data));
          dispatch(toggleFormModalProjectSchedules(false));
        }
        return response;
      });
};
export const editCategoryProjectSchedules = (projectId, categoryId) => {
  return (dispatch) => {
    return dispatch(getCategoryProjectSchedules(projectId, categoryId)).then(
      (response) => {
        if (response.ok) {
          const form = getProjectSchedulesCategoryToForm(response.data);
          dispatch(setFormProjectSchedules(form));
          dispatch(setFormModalProjectSchedules(true));
        }
        return response;
      }
    );
  };
};
export const publishCategoryProjectSchedule = (projectId, categoryId) => {
  return (dispatch) => {
    return projectScheduleApi
      .publishCategoryProjectSchedule(projectId, categoryId)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(
            setModalMessageProjectSchedule(
              'The selected schedule was published successfully.'
            )
          );
        }
        return response;
      });
  };
};
export const archiveCategoryProjectSchedules = (projectId, categoryId) => {
  return (dispatch) => {
    return projectScheduleApi
      .archiveCategoryProjectSchedules(projectId, categoryId)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(removeCategoryProjectSchedules(categoryId));
        }
        return response;
      });
  };
};
const WAIT_EXPORT_TIMEOUT = 120 * 1000;
const waitExportFileCategoryProjectSchedules = (
  projectId,
  categoryId,
  fileType,
  originalFile,
  startTime,
  callback,
  loadingDisabled
) => {
  return (dispatch) => {
    return dispatch(
      getCategoryProjectSchedules(projectId, categoryId, { loadingDisabled })
    ).then((response) => {
      if (response.ok) {
        if (new Date() - startTime < WAIT_EXPORT_TIMEOUT) {
          const file = response.data[`${fileType}_document`]; // pdf_document or excel_document
          if (
            file &&
            file.document &&
            file.document.url &&
            (!originalFile ||
              moment(originalFile.updated_at).isBefore(
                moment(file.updated_at),
                's'
              ))
          ) {
            setTimeout(() => {
              callback(file);
              dispatch(setModalMessageProjectSchedule(null));
            }, 2000);
          } else {
            setTimeout(() => {
              return dispatch(
                waitExportFileCategoryProjectSchedules(
                  projectId,
                  categoryId,
                  fileType,
                  originalFile,
                  startTime,
                  callback,
                  loadingDisabled
                )
              );
            }, 6000);
          }
        } else {
          dispatch(showErrorMessage('Timeout waiting for preview'));
          dispatch(setModalMessageProjectSchedule(null));
        }
      } else {
        dispatch(showErrorMessage(response.errors));
        dispatch(setModalMessageProjectSchedule(null));
      }
      return response;
    });
  };
};
export const exportCategoryProjectSchedules = (projectId, category, type) => {
  return (dispatch) => {
    dispatch(
      setModalMessageProjectSchedule('Making file, please wait...', 'LOADING')
    );
    return projectScheduleApi
      .exportCategoryProjectSchedule(projectId, category.id, type)
      .then((response) => {
        dispatch(setModalMessageProjectSchedule(null));
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          downloadFile(response.data, `Project Schedule - ${category.name}`);
        }
        return response;
      });
  };
};
export const loadPdfPublishScheduleCategory = (
  projectId,
  category,
  loadingDisabled = true
) => {
  return (dispatch) => {
    if (loadingDisabled)
      dispatch(
        setModalMessageProjectSchedule('Making file, please wait...', 'LOADING')
      );
    dispatch(
      waitExportFileCategoryProjectSchedules(
        projectId,
        category.id,
        'pdf',
        category.pdf_document,
        new Date(),
        (file) => dispatch(publishOpenCallback(file)),
        loadingDisabled
      )
    );
  };
};
const publishOpenCallback = (file) => {
  return (dispatch) => {
    dispatch(setModalPdfProjectSchedule(file.document.url));
  };
};
export const deleteCategoryProjectSchedule = (projectId, categoryId) => {
  return (dispatch) => {
    return projectScheduleApi
      .deleteCategoryProjectSchedules(projectId, categoryId)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(removeCategoryProjectSchedules(categoryId));
        }
        return response;
      });
  };
};
export const restoreCategoryProjectSchedule = (projectId, categoryId) => {
  return (dispatch) => {
    return projectScheduleApi
      .restoreCategoryProjectSchedule(projectId, categoryId)
      .then((response) => {
        if (!response.ok) {
          if (response.errors.includes('name already exists')) {
            dispatch(
              setModalMessageProjectSchedule(
                'Schedule name already exists. Please select a new name.',
                'EXCLAMATION'
              )
            );
          } else dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(removeCategoryProjectSchedules(categoryId));
          dispatch(
            setModalMessageProjectSchedule(
              'The selected schedule was restored successfully.'
            )
          );
        }
        return response;
      });
  };
};

// schedules
export const saveProjectSchedule = (projectId, categoryId, schedule) => {
  return (dispatch) => {
    return projectScheduleApi
      .saveProjectSchedule(projectId, categoryId, schedule)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(addProjectSchedule(categoryId, response.data));
        }
        return response;
      });
  };
};
export const orderProjectSchedules = (projectId, categoryId, schedules) => {
  return (dispatch) => {
    return projectScheduleApi
      .orderProjectSchedules(projectId, categoryId, schedules)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(editCategoryValuesProjectSchedules(response.data));
        }
        return response;
      });
  };
};
export const resolveProjectSchedule = (projectId, categoryId, scheduleId) => {
  return (dispatch) => {
    return projectScheduleApi
      .resolveProjectSchedule(projectId, categoryId, scheduleId)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(
            removeProjectScheduleFromCategory(categoryId, response.data)
          );
        }
        return response;
      });
  };
};

export const restoreProjectSchedule = (projectId, categoryId, scheduleId) => {
  return (dispatch) => {
    return projectScheduleApi
      .restoreProjectSchedule(projectId, categoryId, scheduleId)
      .then(async (response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(removeResolvedProjectSchedule(scheduleId));
          dispatch(addCategoryProjectSchedules(response.data));
        }
        return response;
      });
  };
};
export const deleteProjectSchedule = (projectId, categoryId, scheduleId) => {
  return (dispatch) => {
    return projectScheduleApi
      .deleteProjectSchedule(projectId, categoryId, scheduleId)
      .then((response) => {
        if (!response.ok) {
          dispatch(showErrorMessage(response.errors));
        } else {
          dispatch(removeProjectScheduleFromCategory(categoryId, scheduleId));
        }
        return response;
      });
  };
};
export const viewResolvedProjectSchedules = (projectId, categoryId) => {
  return (dispatch) => {
    return dispatch(
      getCategoryProjectSchedules(projectId, categoryId, {
        schedulesStatus: 'resolved',
      })
    ).then((response) => {
      if (!response.ok) {
        dispatch(showErrorMessage(response.errors));
      } else {
        dispatch(setCategoryProjectSchedules(response.data));
        dispatch(setModalResolvedProjectSchedule(true));
      }
      return response;
    });
  };
};

export const updateGanttTasks = (tasks) => ({
  type: UPDATE_GANTT_TASKS,
  payload: tasks,
});

export const updateGanttOptions = (opts) => ({
  type: UPDATE_GANTT_OPTIONS,
  payload: opts,
});

export const sendEmailScheduleCategory = (
  projectId,
  categoryId,
  emails,
  subject,
  message,
  fileType
) => (dispatch) => {
  return projectScheduleApi
    .sendEmail(projectId, categoryId, emails, subject, message, fileType)
    .then((response) => {
      if (response.ok) {
        dispatch(setShowEmailModal(false));
      } else {
        dispatch(showErrorMessage(response.errors));
      }
      return response;
    });
};
