export const SCHEDULE_TABS = [
  { title: 'Schedules', id: 'schedules' },
  { title: 'Published', id: 'published' },
  { title: 'Archived', id: 'archived' },
];
export const DEFAULT_SCHEDULE_TAB = 'schedules';

export const SCHEDULE_FORM_TABS = [
  { title: 'General Info', id: 'general_info' },
  { title: 'Permissions', id: 'permissions' },
  { title: 'Editing', id: 'editing' },
];
export const DEFAULT_SCHEDULE_FORM_TAB = 'general_info';

export const alphabeticalFilterOptions = [
  { id: 'all', label: 'All' },
  { id: 'asc', label: 'Ascending' },
  { id: 'desc', label: 'Descending' },
];
export const dateFilterOptions = [
  { id: 'all', label: 'All' },
  { id: 'desc', label: 'Newest' },
  { id: 'asc', label: 'Oldest' },
];

export const SCHEDULES_TABLE_HEADERS = [
  'DESCRIPTION',
  'DURATION',
  'ORIGINAL START',
  'PREVIOUS START',
  'CURRENT START',
  'START VARIANCE',
  'ORIGINAL COMPLETION',
  'PREVIOUS COMPLETION',
  'CURRENT COMPLETION',
  'COMPLETION VARIANCE',
  'RESPONSIBLE',
  'ACTION',
];

class Schedule {
  isSharedView = (category) => category.visibility === 'shared';
  isPublicView = (category) => category.visibility === 'public';
  isSharedEdit = (category) => category.editing === 'shared';
  isPublicEdit = (category) => category.editing === 'public';

  isCreator = (user, cat) =>
    !!cat && !!cat.created_by && user.id === cat.created_by.id;

  hasSharedView = (user, category) =>
    this.isSharedView(category) &&
    category.visibility_users &&
    category.visibility_users.find((u) => u.id === user.id);

  hasSharedEdit = (user, category) =>
    this.isSharedEdit(category) &&
    category.editing_users &&
    category.editing_users.find((u) => u.id === user.id);

  setStartDate = (row) => {
    if (row.current_start) return new Date(row.current_start);
    if (row.previous_start) return new Date(row.previous_start);
    if (row.original_start) return new Date(row.original_start);
    return new Date();
  };
  setEndDate = (row) => {
    if (row.current_completion) return new Date(row.current_completion);
    if (row.previous_completion) return new Date(row.previous_completion);
    if (row.original_completion) return new Date(row.original_completion);
    return new Date();
  };

  schedulesToTasksProp = (rows, parentDescription) => {
    // this is for tasks inside a category
    if (!rows || (rows && rows.length === 0)) return [];
    const tasks = rows.map((r, index) => {
      const startDate = this.setStartDate(r);
      const endDate = this.setEndDate(r);
      return {
        id: r.id,
        start: startDate,
        end: endDate,
        name: r.description,
        /* progress: 10, */
        type: 'task',
        displayOrder: index + 2,
        category: parentDescription,
        project: parentDescription,
        parent: parentDescription,
      };
    });
    return tasks;
  };

  categoryStartDate = (cat) => {
    let startDate = null;

    if (!cat.schedules || cat.schedules.length === 0) {
      return startDate;
    }

    const validDates = (key) =>
      cat.schedules
        .map((schedule) => schedule[key])
        .filter((date) => date != null);

    // Check for current_start dates
    let dates = validDates('current_start');
    if (dates.length === 0) {
      // Fallback to previous_start if no valid current_start
      dates = validDates('previous_start');
    }
    if (dates.length === 0) {
      // Fallback to original_start if no valid previous_start
      dates = validDates('original_start');
    }

    if (dates.length > 0) {
      // Find the earliest date
      const earliestDate = dates.reduce((earliest, date) =>
        new Date(date) < new Date(earliest) ? date : earliest
      );
      startDate = new Date(earliestDate);
    }

    return startDate;
  };

  categoryCompletionDate = (cat) => {
    let completionDate = null;

    if (!cat.schedules || cat.schedules.length === 0) {
      return completionDate;
    }

    const validDates = (key) =>
      cat.schedules
        .map((schedule) => schedule[key])
        .filter((date) => date != null);

    // Check for current_completion dates
    let dates = validDates('current_completion');
    if (dates.length === 0) {
      // Fallback to previous_completion if no valid current_completion
      dates = validDates('previous_completion');
    }
    if (dates.length === 0) {
      // Fallback to original_completion if no valid previous_completion
      dates = validDates('original_completion');
    }

    if (dates.length > 0) {
      // Find the latest date
      const latestDate = dates.reduce((latest, date) =>
        new Date(date) > new Date(latest) ? date : latest
      );
      completionDate = new Date(latestDate);
    }

    return completionDate;
  };

  categoriesToTasksProp = (categories, stateTasks, categoryProp) => {
    if (!categories || categories.length === 0) return [];

    const allTasks = categories.flatMap((category) => {
      const startDate = this.categoryStartDate(category);
      const endDate = this.categoryCompletionDate(category);

      // Skip the category if it has null start and end dates
      if (!startDate || !endDate) return [];

      // Find the corresponding task in stateTasks with type: 'project'

      // Use the hideChildren value from the corresponding task if it exists, default to true
      const catId = categoryProp && categoryProp.id;

      const hideChildren = catId === category.id ? false : true;

      // Create the main task for the schedule category
      const mainTask = {
        id: category.id,
        start: startDate,
        end: endDate,
        name: category.name || category.description || 'Unnamed Category',
        /* progress: 10, // Default progress value */
        type: 'project', // To make it different from the other tasks
        hideChildren, // Use the determined hideChildren value
        isDisabled: true,
      };

      // Create tasks for each schedule inside the category
      const scheduleTasks = this.schedulesToTasksProp(
        category.schedules,
        category.id
      ).map((task) => {
        return {
          ...task,
        };
      });

      return [mainTask, ...scheduleTasks];
    });

    return allTasks;
  };
}

export default new Schedule();
