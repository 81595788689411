import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Gantt, ViewMode } from '@wamra/gantt-task-react';
import '@wamra/gantt-task-react/dist/style.css';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';

import { getDirectory } from 'components/admin/projects/details/project-directory/store/selectors';
import { getCurrentProject } from 'components/admin/projects/details/store/selectors';
import { getCurrentUser } from 'selectors/authentication';

import { getDarkMode } from 'selectors/theme';

import SchedulePermissions from 'permissions/schedule-permissions';

import {
  saveProjectSchedule,
  updateGanttTasks,
  updateGanttOptions,
} from './store/actions';

import {
  getProjectScheduleFilters,
  getProjectSchedules,
  getProjectSchedulesGanttTasks,
  getGanttOptions,
} from './store/selectors';

import './GanttChartNew.css';

const ViewSwitcher = ({ onViewModeChange }) => {
  return (
    <div className="gantt-view-container">
      <Button
        onClick={() => onViewModeChange(ViewMode.Day)}
        type={BUTTON_TYPES.CONFIRMATION}
        color={BUTTON_COLORS.GREEN}
        label="Day"
      />

      <Button
        onClick={() => onViewModeChange(ViewMode.Week)}
        type={BUTTON_TYPES.CONFIRMATION}
        color={BUTTON_COLORS.GREEN}
        label="Week"
      />

      <Button
        onClick={() => onViewModeChange(ViewMode.Month)}
        type={BUTTON_TYPES.CONFIRMATION}
        color={BUTTON_COLORS.GREEN}
        label="Month"
      />

      <Button
        onClick={() => onViewModeChange(ViewMode.Year)}
        type={BUTTON_TYPES.CONFIRMATION}
        color={BUTTON_COLORS.GREEN}
        label="Year"
      />
    </div>
  );
};

const GanttChart = (props) => {
  const {
    links = [{ id: 1, source: 20, target: 21, type: 'e2e' }],
    project,
    currentUser,
    schedules,
    dispatch,
    ganttTasks,
    ganttOptions,
    isDarkMode,
  } = props;

  const [view, setView] = useState(ViewMode.Day);
  const [tasks, setTasks] = useState([]);

  const onGanttChange = (row, cat) => {
    if (!cat) return;
    dispatch(saveProjectSchedule(project.id, cat.id, row));
  };

  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    setTasks(ganttTasks);
  }, []);

  useEffect(() => {
    dispatch(updateGanttTasks(tasks));
  }, [tasks]);

  useEffect(() => {
    dispatch(updateGanttOptions({ ...ganttOptions, viewMode: view }));
  }, [view]);

  const handleTaskChange = (task) => {
    // Find the index of the task in the current tasks array
    const taskIndex = ganttTasks.findIndex((t) => t.id === task.id);
    if (taskIndex === -1) {
      console.log(`Task with ID ${task.id} not found.`);
      return;
    }

    // Update local ganttTasks state
    const updatedTasks = [...ganttTasks];
    updatedTasks[taskIndex] = task;

    // Update Redux store
    const categoryId = task.category; // task.category contains the schedule category ID
    const category = schedules.find((s) => s.id === categoryId);
    if (!SchedulePermissions.canEdit(currentUser, project, category)) return;
    const updatedFields = {};
    if (task.start !== ganttTasks[taskIndex].start) {
      updatedFields.current_start = task.start;
    }
    if (task.end !== ganttTasks[taskIndex].end) {
      updatedFields.current_completion = task.end;
    }

    const allSchedules = schedules.reduce((acc, originalSchedule) => {
      if (originalSchedule.schedules) {
        acc = acc.concat(originalSchedule.schedules);
      }
      return acc;
    }, []);
    const wantedSchedule = allSchedules.find(
      (schedule) => schedule.id === task.id
    );

    let updatedSchedule = { ...wantedSchedule, ...updatedFields };

    // Check if wantedSchedule has a responsible object with an id
    if (wantedSchedule.responsible && wantedSchedule.responsible.id) {
      updatedSchedule = {
        ...updatedSchedule,
        responsible: {
          ...wantedSchedule.responsible,
          value: wantedSchedule.responsible.id, // Add the additional key-value pair
        },
      };
    }
    // Do the same for responsible_company

    if (
      wantedSchedule.responsible_company &&
      wantedSchedule.responsible_company.id
    ) {
      updatedSchedule = {
        ...updatedSchedule,
        responsible_company: {
          ...wantedSchedule.responsible_company,
          value: wantedSchedule.responsible_company.id, // Add the additional key-value pair
        },
      };
    }

    onGanttChange(updatedSchedule, category);
  };

  const handleExpanderClick = (task) => {
    dispatch(
      updateGanttTasks(
        ganttTasks.map((t) => {
          return t.id === task.id ? { ...t, hideChildren: !t.hideChildren } : t;
        })
      )
    );
  };

  const checkGanttTasksAreComplete = (allTasks) => {
    if (!(allTasks && allTasks.length > 0)) return false;
    for (let i = 0; i < allTasks.length; i++) {
      if (!allTasks[i]) return false;
    }
    return true;
  };

  const ganttView = (ganttOptions && ganttOptions.viewMode) || 'Month';
  if (
    !(
      ganttTasks &&
      ganttTasks.length > 0 &&
      checkGanttTasksAreComplete(ganttTasks)
    )
  )
    return null;

  const greenColor = isDarkMode ? '#d4fb00' : '#00b091';
  const chartBackgroundColor = isDarkMode ? '#43495a' : '#fff';
  const textColor = isDarkMode ? '#fff' : '#000000';
  return (
    <div className="gantt-wrapper">
      <div className="gantt-chart-title">Gantt Chart</div>
      <ViewSwitcher onViewModeChange={(viewMode) => setView(viewMode)} />
      <Gantt
        tasks={ganttTasks}
        links={links}
        columnWidth={columnWidth}
        viewMode={ganttView}
        distances={{ tableWidth: 0 }}
        ganttHeight={100}
        colors={{
          projectBackgroundColor: greenColor,
          selectedTaskBackgroundColor: chartBackgroundColor,
          barProgressSelectedColor: greenColor,
          barBackgroundSelectedColor: greenColor,
          barProgressColor: greenColor,
          oddTaskBackgroundColor: chartBackgroundColor,
          evenTaskBackgroundColor: chartBackgroundColor,
          barBackgroundColor: greenColor,
          contextMenuTextColor: greenColor,
          projectBackgroundSelectedColor: greenColor,
          projectProgressColor: greenColor,
          barLabelColor: textColor,
          holidayBackgroundColor: chartBackgroundColor,
        }}
        dependencies
        onDateChange={handleTaskChange}
        ganttWidth={5000}
        eventOption={{ onExpanderClick: handleExpanderClick }}
      />
    </div>
  );
};

export default connect((state) => ({
  currentUser: getCurrentUser(state),
  project: getCurrentProject(state),
  filters: getProjectScheduleFilters(state),
  directory: getDirectory(state),
  schedules: getProjectSchedules(state),
  ganttTasks: getProjectSchedulesGanttTasks(state),
  ganttOptions: getGanttOptions(state),
  isDarkMode: getDarkMode(state),
}))(GanttChart);
