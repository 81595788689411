import { createSelector } from 'reselect';

// data
export const getSecurityCamerasConfiguration = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.securityCameras.data.configuration,
  (configuration) => configuration
);

// ui
export const getSecurityCamerasConfigurationForm = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.securityCameras.ui.configurationForm,
  (configurationForm) => configurationForm
);
export const getSecurityCamerasCamerasForm = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.securityCameras.ui.camerasForm,
  (camerasForm) => camerasForm
);
export const getSecurityCamerasPermissionsForm = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.securityCameras.ui.permissionsForm,
  (permissionsForm) => permissionsForm
);
export const getShowTrueLookIframe = createSelector(
  (state) =>
    state.projects.scenes.details.scenes.securityCameras.ui.showTrueLookIframe,
  (showTrueLookIframe) => showTrueLookIframe
);
