import {
  PROJECT_SCHEDULE_ADD_CATEGORY,
  PROJECT_SCHEDULE_ADD_SCHEDULE,
  PROJECT_SCHEDULE_EDIT_CATEGORY,
  PROJECT_SCHEDULE_INIT,
  PROJECT_SCHEDULE_REMOVE_FROM_CATEGORY,
  PROJECT_SCHEDULE_SET,
  PROJECT_SCHEDULE_SET_FORM,
  PROJECT_SCHEDULE_REMOVE_CATEGORY,
  PROJECT_SCHEDULE_SET_CATEGORY,
  PROJECT_SCHEDULE_REMOVE_RESOLVED,
  PROJECT_SCHEDULE_GANTT_INFORMATION_UPDATE,
  UPDATE_GANTT_TASKS,
  UPDATE_GANTT_OPTIONS,
} from './action-types';

import Schedule from 'domain/schedule';

export const initialState = {
  schedules: [],
  form: {
    id: null,
    name: '',
    duplicate: null,
    copyDates: false,
    copyVisibility: false,
    copyEditing: false,
    visibility: 'private',
    visibilityUsers: [],
    editing: 'private',
    editingUsers: [],
    createdBy: null,
    createdAt: null,
    ganttTasks: [],
    ganttOptions: { viewMode: 'Daily' },
  },
  category: null,
};
const getYearDifference = (date1, date2) => {
  if (!date1 || !date2) return 0; // Handle undefined or null dates
  return Math.abs(
    new Date(date1).getFullYear() - new Date(date2).getFullYear()
  );
};

export default function projectScheduleDataReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case PROJECT_SCHEDULE_INIT:
      return initialState;
    case PROJECT_SCHEDULE_SET:
      return {
        ...state,
        schedules: action.payload,
      };
    case PROJECT_SCHEDULE_ADD_CATEGORY: {
      const schedules = [...state.schedules];
      const category = action.payload;

      const index = schedules.findIndex((c) => c.id === category.id);
      if (index >= 0) schedules[index] = category;
      else schedules.push(category);

      return { ...state, schedules };
    }
    case PROJECT_SCHEDULE_EDIT_CATEGORY: {
      const { categoryId, category } = action.payload;

      const schedules = state.schedules;
      const categoryIndex = schedules.findIndex((c) => c.id === categoryId);
      if (categoryIndex < 0) return state;

      const oldCategory = state.schedules[categoryIndex];
      schedules[categoryIndex] = { ...oldCategory, ...category };

      return { ...state, schedules };
    }
    case PROJECT_SCHEDULE_REMOVE_CATEGORY: {
      const schedules = state.schedules.filter((c) => c.id !== action.payload);
      return { ...state, schedules };
    }
    case PROJECT_SCHEDULE_ADD_SCHEDULE: {
      const { categoryId, schedule } = action.payload;

      const schedules = state.schedules;
      const categoryIndex = schedules.findIndex((c) => c.id === categoryId);
      if (categoryIndex < 0) return state;

      const category = state.schedules[categoryIndex];
      const index = category.schedules.findIndex((s) => s.id === schedule.id);

      // The following is a catch for the case in which the useer inputs a year change
      // of more than 10 years. When that happens, a small delay is seen because the chart
      // has to re-render on a new scale (10 years would be 3650 more days approx...)
      // So to avoid this, change the view to yearly, that way instead of jumping (365 * years) days
      // you would jump an years ammount in the yearly scale.

      let hasSignificantChange = false;

      if (index >= 0) {
        const existingSchedule = category.schedules[index];
        // Compare dates
        const fieldsToCheck = [
          'current_start',
          'previous_start',
          'original_start',
          'current_completion',
          'previous_completion',
          'original_completion',
        ];

        hasSignificantChange = fieldsToCheck.some((field) => {
          const oldValue = existingSchedule[field];
          const newValue = schedule[field];
          return getYearDifference(oldValue, newValue) > 10;
        });
        // Continue with the rest of the reducer case
        // Update the schedule
        category.schedules[index] = schedule;
      } else {
        // If a new schedule is added, no comparison is needed
        category.schedules.push(schedule);
      }

      const ganttTasks = Schedule.categoriesToTasksProp(
        schedules,
        state.ganttTasks,
        category
      );
      return {
        ...state,
        schedules,
        ganttTasks,
        ganttOptions: hasSignificantChange
          ? { viewMode: 'Year' }
          : state.ganttOptions,
      };
    }

    case PROJECT_SCHEDULE_REMOVE_FROM_CATEGORY: {
      const { categoryId, scheduleId } = action.payload;

      const schedules = state.schedules;
      const categoryIndex = schedules.findIndex((c) => c.id === categoryId);
      if (categoryIndex < 0) return state;

      const category = state.schedules[categoryIndex];
      category.schedules = category.schedules.filter(
        (s) => s.id !== scheduleId
      );

      return { ...state, schedules };
    }
    case PROJECT_SCHEDULE_SET_FORM:
      return {
        ...state,
        form: { ...state.form, ...action.payload },
      };
    case PROJECT_SCHEDULE_SET_CATEGORY:
      return { ...state, category: action.payload };
    case PROJECT_SCHEDULE_REMOVE_RESOLVED: {
      const category = {
        ...state.category,
        resolved_schedules: state.category.resolved_schedules.filter(
          (s) => s.id !== action.payload
        ),
      };
      return { ...state, category };
    }
    case PROJECT_SCHEDULE_GANTT_INFORMATION_UPDATE: {
      const ganttTasks = Schedule.categoriesToTasksProp(
        action.payload,
        state.ganttTasks,
        {}
      );
      return { ...state, ganttTasks };
    }
    case UPDATE_GANTT_TASKS:
      return { ...state, ganttTasks: action.payload };
    case UPDATE_GANTT_OPTIONS:
      return { ...state, ganttOptions: action.payload };
    default:
      return state;
  }
}
