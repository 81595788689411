export const PROJECT_SCHEDULE_INIT = 'PROJECT_SCHEDULE_INIT';
export const PROJECT_SCHEDULE_SET_FILTERS = 'PROJECT_SCHEDULE_SET_FILTERS';
export const PROJECT_SCHEDULE_SET = 'PROJECT_SCHEDULE_SET';
export const PROJECT_SCHEDULE_ADD_CATEGORY = 'PROJECT_SCHEDULE_ADD_CATEGORY';
export const PROJECT_SCHEDULE_EDIT_CATEGORY = 'PROJECT_SCHEDULE_EDIT_CATEGORY';
export const PROJECT_SCHEDULE_REMOVE_CATEGORY =
  'PROJECT_SCHEDULE_REMOVE_CATEGORY';
export const PROJECT_SCHEDULE_ADD_SCHEDULE = 'PROJECT_SCHEDULE_ADD_SCHEDULE';
export const PROJECT_SCHEDULE_REMOVE_FROM_CATEGORY =
  'PROJECT_SCHEDULE_REMOVE_FROM_CATEGORY';

export const PROJECT_SCHEDULE_SET_FORM_MODAL =
  'PROJECT_SCHEDULE_SET_FORM_MODAL';
export const PROJECT_SCHEDULE_SET_FORM_TAB = 'PROJECT_SCHEDULE_SET_FORM_TAB';
export const PROJECT_SCHEDULE_SET_FORM = 'PROJECT_SCHEDULE_SET_FORM';

export const PROJECT_SCHEDULE_SET_MESSAGE = 'PROJECT_SCHEDULE_SET_MESSAGE';

export const PROJECT_SCHEDULE_SET_EMAIL_MODAL =
  'PROJECT_SCHEDULE_SET_EMAIL_MODAL';
export const PROJECT_SCHEDULE_SET_PDF_MODAL = 'PROJECT_SCHEDULE_SET_PDF_MODAL';
export const PROJECT_SCHEDULE_SET_CATEGORY = 'PROJECT_SCHEDULE_SET_CATEGORY';
export const PROJECT_SCHEDULE_SET_RESOLVED_MODAL =
  'PROJECT_SCHEDULE_SET_RESOLVED_MODAL';
export const PROJECT_SCHEDULE_REMOVE_RESOLVED =
  'PROJECT_SCHEDULE_REMOVE_RESOLVED';
export const PROJECT_SCHEDULE_GANTT_INFORMATION_UPDATE =
  'PROJECT_SCHEDULE_GANTT_INFORMATION_UPDATE';
export const UPDATE_GANTT_TASKS = 'UPDATE_GANTT_TASKS';
export const UPDATE_GANTT_OPTIONS = 'UPDATE_GANTT_OPTIONS';
