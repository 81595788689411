import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './RfisTable.css';
import SimpleTable from 'components/shared/table/SimpleTable';
import SimplePagination from 'components/shared/pagination/SimplePagination';
import Date from 'components/shared/text/Date';
import RfiModalContainer from 'components/admin/projects/details/project-rfi-log/RfiModalContainer';
import { formatNumber } from 'services/utils/text-util';
import { getCurrentUser } from 'selectors/authentication';
import {
  getRfis,
  getCurrentStatus,
  getTotal,
  getCurrentPage,
  getPageSize,
} from 'components/admin/rfis/store/selectors';
import { loadProjectRequest } from 'components/admin/projects/details/store/actions';
import { openRfi } from 'components/admin/projects/details/project-rfi-log/store/actions';
import User from 'domain/user';

class RfisTable extends Component {
  static propTypes = {
    onPageChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { showModal: false };
  }

  open = (id) => {
    const rfi = this.props.rfis.find((rfi) => rfi.id === id);
    const { currentUser, dispatch } = this.props;
    this.setState({ showModal: true });
    dispatch(loadProjectRequest(rfi.project_id)).then(({ project }) => {
      dispatch(
        openRfi(rfi.project_id, rfi.id, User.isContractor(currentUser, project))
      );
    });
  };

  close = () => {
    const { currentPage, currentStatus, onPageChange } = this.props;
    this.setState({ showModal: false });
    onPageChange(currentPage, currentStatus);
  };

  handleGenerateLink = (id) => {
    const { rfis } = this.props;
    const rfi = rfis.find((rfi) => rfi.id === id);
    return `projects/${rfi.project_id}/rfis/rfi/${id}`;
  };

  mapRfis = () => {
    return this.props.rfis.map((rfi) => {
      return {
        id: rfi.id,
        data: [
          rfi.project_number,
          rfi.project_name,
          formatNumber(rfi.number),
          rfi.csi_code.division,
          rfi.title || '-',
          rfi.due_date ? (
            <Date
              className="due-date"
              value={
                rfi.extended_due_date ? rfi.extended_due_date : rfi.due_date
              }
              format="MM/DD/YYYY"
            />
          ) : (
            '-'
          ),
        ],
      };
    });
  };

  render() {
    const {
      currentStatus,
      total,
      currentPage,
      pageSize,
      onPageChange,
    } = this.props;
    const { showModal } = this.state;
    return (
      <div className="rfis-table">
        <SimpleTable
          headers={[
            'PROJECT NO',
            'PROJECT NAME',
            'RFI NO',
            'COST CODE',
            'TITLE',
            'DUE',
          ]}
          rows={this.mapRfis()}
          emptyMessage="No RFI's added yet."
          onRowSelected={this.open}
          onGenerateLink={this.handleGenerateLink}
        />
        <div className="pagination-container">
          <SimplePagination
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
            onPageChange={(page) => onPageChange(page, currentStatus)}
          />
        </div>

        {showModal && <RfiModalContainer onClose={this.close} />}
      </div>
    );
  }
}

export default connect((state) => {
  return {
    currentUser: getCurrentUser(state),
    rfis: getRfis(state),
    currentStatus: getCurrentStatus(state),
    total: getTotal(state),
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
  };
})(RfisTable);
