import React from 'react';
import { connect } from 'react-redux';

import FormModal from 'components/shared/modal/FormModal';
import Button, {
  BUTTON_TYPES,
  BUTTON_COLORS,
} from 'components/shared/button/Button';
import Input from 'components/shared/input/Input';
import Selector from 'components/shared/selector/Selector';
import FormControlBlock from 'components/shared/form/FormControlBlock';

import { SECURITY_CAMERA_CONNECTORS } from 'domain/security-camera';

import { getCurrentProject } from 'components/admin/projects/details/store/selectors';

import { getSecurityCamerasConfigurationForm } from './store/selectors';
import {
  setSecurityCameraConfigurationForm,
  setSecurityCameraConfigurationFormValue,
  saveSecurityCameraConfiguration,
  setShowTrueLookIframe,
} from './store/actions';
import './ConfigureModal.css';

const ConfigureModal = ({ dispatch, currentProject, configurationForm }) => {
  const handleClose = () => dispatch(setSecurityCameraConfigurationForm());

  const canSubmit = () => {
    if (!configurationForm.connector) return true;

    switch (configurationForm.connector) {
      case 'hikvision':
        return configurationForm.url && configurationForm.username;
      default:
        return false;
    }
  };

  const handleSubmit = () =>
    dispatch(
      saveSecurityCameraConfiguration(currentProject.id, configurationForm)
    );

  const handleFormChange = (name, value) => {
    if (name === 'connector' && value === 'truelook') {
      dispatch(setShowTrueLookIframe(true));
      handleClose();
    } else {
      dispatch(setShowTrueLookIframe(false));
      dispatch(setSecurityCameraConfigurationFormValue(name, value));
    }
  };

  const renderHikvisionConnector = () => (
    <>
      <div className="form-block">
        <FormControlBlock
          label={!!configurationForm.url && 'Url'}
          control={
            <Input
              placeholder="Url"
              value={configurationForm.url}
              onChange={(value) => handleFormChange('url', value)}
            />
          }
        />
      </div>
      <div className="form-block">
        <FormControlBlock
          label={!!configurationForm.username && 'Username'}
          control={
            <Input
              placeholder="Username"
              value={configurationForm.username}
              onChange={(value) => handleFormChange('username', value)}
            />
          }
        />
        <FormControlBlock
          label={!!configurationForm.password && 'Password'}
          control={
            <Input
              type="password"
              placeholder="Password"
              value={configurationForm.password}
              onChange={(value) => handleFormChange('password', value)}
            />
          }
        />
      </div>
    </>
  );

  const renderConnector = () => {
    switch (configurationForm.connector) {
      case 'hikvision':
        return renderHikvisionConnector();
      default:
        return null;
    }
  };

  return (
    <FormModal
      className="security-cameras-configure-modal"
      show
      onClose={handleClose}
      subTitle="Configure Security Cameras"
    >
      <div className="form-block">
        <FormControlBlock
          hideBottomLabel
          className="input-type"
          label={configurationForm.connector && 'Connector'}
          control={
            <Selector
              placeholder="Connector"
              options={SECURITY_CAMERA_CONNECTORS}
              value={configurationForm.connector}
              onChange={(option) => handleFormChange('connector', option.value)}
            />
          }
        />
      </div>
      {renderConnector()}
      <div className="buttons-container">
        <Button
          type={BUTTON_TYPES.RECTANGLE}
          color={BUTTON_COLORS.WHITE}
          className="button-cancel"
          label="CANCEL"
          onClick={handleClose}
        />
        <Button
          type={BUTTON_TYPES.CONFIRMATION}
          color={BUTTON_COLORS.GREEN}
          disabled={!canSubmit()}
          label="SAVE"
          onClick={handleSubmit}
        />
      </div>
    </FormModal>
  );
};

export default connect((state) => {
  return {
    currentProject: getCurrentProject(state),
    configurationForm: getSecurityCamerasConfigurationForm(state),
  };
})(ConfigureModal);
