import { useEffect, useState } from 'react';
import {
  setShowEmailModal,
  setRecipients,
} from 'components/admin/projects/details/project-email-modal/store/actions';

import {
  removeCloseScheduleCategory,
  persistCloseScheduleCategory,
  getPersistedCloseScheduleCategory,
} from '../store/persist';
import {
  deleteProjectSchedule,
  editCategoryProjectSchedules,
  orderProjectSchedules,
  publishCategoryProjectSchedule,
  archiveCategoryProjectSchedules,
  resolveProjectSchedule,
  saveProjectSchedule,
  loadCategoryProjectSchedules,
  setModalEmailTypeProjectSchedule,
  exportCategoryProjectSchedules,
  viewResolvedProjectSchedules,
  setModalMessageProjectSchedule,
} from './store/actions';
import SchedulePermissions from 'permissions/schedule-permissions';
import { handlePasteTableFromExcel } from 'services/utils/import-utils';
import moment from 'moment';

const useSchedulesCategory = (props) => {
  const {
    dispatch,
    category,
    currentUser,
    project,
    directory,
    projectSchedules,
    ganttTasks,
  } = props;

  const wasClosed = () =>
    !!getPersistedCloseScheduleCategory(currentUser.id, category.id);

  const sortByOrder = (s1, s2) => s1.position - s2.position;
  const parseSchedule = (s) => ({
    ...s,
    responsible: s.responsible
      ? { value: s.responsible.id, label: s.responsible.full_name }
      : null,

    responsible_company: s.responsible_company
      ? {
          value: s.responsible_company_id,
          label: s.responsible_company.name,
        }
      : null,
  });

  const [isCollapsed, setCollapsed] = useState(
    wasClosed() || category.schedules.length === 0
  );
  const [schedules, setSchedules] = useState(
    category.schedules.map(parseSchedule).sort(sortByOrder) || []
  );

  useEffect(() => {
    setSchedules(category.schedules.map(parseSchedule).sort(sortByOrder));
    setCollapsed(wasClosed() || category.schedules.length === 0);
  }, [category]);

  useEffect(() => {
    const categoryToUpdate = projectSchedules.find(
      (ps) => ps.id === category.id
    );
    setSchedules(
      categoryToUpdate.schedules.map(parseSchedule).sort(sortByOrder)
    );
  }, [ganttTasks]);

  const onOrderChange = (r) => {
    const to = r.destination.index;
    const from = r.source.index;

    const newSchedules = [...schedules];
    const schedule = newSchedules.splice(from, 1)[0];
    newSchedules.splice(to, 0, schedule);
    newSchedules.forEach((s, i) => (s.position = i));

    setSchedules(newSchedules);
    dispatch(orderProjectSchedules(project.id, category.id, newSchedules));
  };

  const isNewSchedule = (s) => typeof s.id === 'undefined' || s.id === null;

  const validateSchedule = (s) => {
    let errors = {};
    if (!s.description || s.description.length === 0) errors.description = true;
    return errors;
  };

  const onScheduleSave = (s, i) => {
    const isNew = isNewSchedule(s);
    dispatch(saveProjectSchedule(project.id, category.id, s)).then(
      (response) => {
        if (response.ok && isNew)
          onRowValueChange('id', s, i)(response.data.id);
      }
    );
  };

  const onRowChange = (i, save = true) => (row) => {
    const schedule = { ...row };
    // validate
    const errors = validateSchedule(schedule);
    schedule.errors = errors;
    // new list
    const newSchedules = [...schedules];
    newSchedules[i] = schedule;
    setSchedules(newSchedules);
    // save
    if (Object.keys(errors).length === 0 && save) onScheduleSave(schedule, i);
  };

  const onRowValueChange = (attr, row, i) => (value) => {
    // new item
    const schedule = { ...row, [attr]: value };
    if (attr === 'original_start' && !row.current_start)
      schedule.current_start = value;
    if (attr === 'original_completion' && !row.current_completion)
      schedule.current_completion = value;

    const attr_not_to_save = ['id', 'status'];
    const save = !attr_not_to_save.includes(attr);
    onRowChange(i, save)(schedule);
  };

  const onResolveSchedule = (row) => {
    dispatch(resolveProjectSchedule(project.id, category.id, row.id)).then(
      (response) => {
        if (response.ok) handleRemoveSchedule(row);
      }
    );
  };

  const handleRemoveSchedule = (row) => {
    const newSchedules = schedules.filter((s) => s.id !== row.id);
    if (newSchedules.length === 0) setCollapsed(true);
    setSchedules(newSchedules);
  };

  const onDeleteSchedule = (row) => {
    handleRemoveSchedule(row);
    const isNew = isNewSchedule(row);
    if (!isNew) {
      dispatch(deleteProjectSchedule(project.id, category.id, row.id));
    }
  };

  const addSchedule = () => {
    const newSchedule = {
      id: null,
      description: '',
      original_start: null,
      previous_start: null,
      current_start: null,
      original_completion: null,
      previous_completion: null,
      current_completion: null,
      created_by: { ...currentUser },
      status: 'default',
      position: schedules.length,
    };
    const newSchedules = [...schedules, newSchedule];
    setSchedules(newSchedules);
    setCollapsed(false);
  };
  const viewResolved = () => {
    dispatch(viewResolvedProjectSchedules(project.id, category.id));
  };

  const publishPdf = () => {
    dispatch(publishCategoryProjectSchedule(project.id, category.id));
  };
  const editCategory = () => {
    dispatch(editCategoryProjectSchedules(project.id, category.id));
  };
  const archiveCategory = () => {
    dispatch(archiveCategoryProjectSchedules(project.id, category.id));
  };
  const sendEmail = (type = 'pdf') => () => {
    dispatch(loadCategoryProjectSchedules(project.id, category.id)).then(
      (response) => {
        if (response.ok) {
          dispatch(setModalEmailTypeProjectSchedule(type));
          dispatch(setShowEmailModal(true));
          dispatch(setRecipients([[currentUser.id, currentUser.email]]));
        }
      }
    );
  };
  const exportCategory = (type = 'pdf') => () => {
    dispatch(exportCategoryProjectSchedules(project.id, category, type));
  };

  const onImportFromExcel = (row, i) => (e) => {
    const table = handlePasteTableFromExcel(e);
    if (table.length > 0) {
      // get date columns
      const dateColumns = [];
      table[0].forEach((_, col) => {
        const isDateColumn = table.slice(1).every((r) => {
          const content = r[col];
          return content === '' || moment(content).isValid();
        });
        if (isDateColumn) dateColumns.push(col);
      });
      // keep description (col == 0) and date columns
      const filteredTable = table.map((r) => {
        return r.filter((_, col) => col === 0 || dateColumns.includes(col));
      });

      // transform arrays to schedules
      filteredTable.forEach((r, j) => {
        if (r.length > 0 && r[0] && r[0].length > 0) {
          const wasAdded = j === 0;
          const s = {
            id: wasAdded ? row.id : null,
            description: r[0],
            original_start:
              r[1] && moment(r[1]).isValid()
                ? moment(r[1]).format('YYYY-MM-DD')
                : null,
            previous_start:
              r[2] && moment(r[2]).isValid()
                ? moment(r[2]).format('YYYY-MM-DD')
                : null,
            current_start:
              r[3] && moment(r[3]).isValid()
                ? moment(r[3]).format('YYYY-MM-DD')
                : null,
            original_completion:
              r[4] && moment(r[4]).isValid()
                ? moment(r[4]).format('YYYY-MM-DD')
                : null,
            previous_completion:
              r[5] && moment(r[5]).isValid()
                ? moment(r[5]).format('YYYY-MM-DD')
                : null,
            current_completion:
              r[6] && moment(row[6]).isValid()
                ? moment(row[6]).format('YYYY-MM-DD')
                : null,
            created_by: { ...currentUser },
            position: wasAdded ? i : schedules.length + j,
          };
          dispatch(
            setModalMessageProjectSchedule(
              'Importing Data, please wait...',
              'LOADING'
            )
          );
          dispatch(saveProjectSchedule(project.id, category.id, s)).then(
            (response) => {
              if (response.ok) {
                setSchedules((prevSchedules) => {
                  const schedule = { ...response.data, copied: true };

                  const res = [...prevSchedules];
                  if (wasAdded) res[i] = schedule;
                  else res.push(schedule);

                  return res;
                });
                dispatch(setModalMessageProjectSchedule(null));
              }
            }
          );
        }
      });
    }
  };

  const persistCloseCategories = (open) => {
    setCollapsed(!open);
    if (open) removeCloseScheduleCategory(currentUser.id, category.id);
    else persistCloseScheduleCategory(currentUser.id, category.id);
  };

  const getResponsibleOptions = () => {
    const options = [];
    if (directory) {
      directory.project_contributors.forEach((c) => {
        if (!options.find((o) => o.value === c.company_id)) {
          const opt = {
            value: c.company_id,
            label: c.company.name,
            subOptions: c.users.map((u) => ({
              value: u.id,
              label: u.full_name,
            })),
          };
          options.push(opt);
        }
      });
    }
    return options;
  };

  // PERMISSIONS
  const canEdit = SchedulePermissions.canEdit(currentUser, project, category);
  const canView = SchedulePermissions.canView(currentUser, project, category);

  return {
    schedules,
    isCollapsed,
    canResolve: canEdit,
    canDelete: canEdit,
    canAdd: canEdit,
    canPublish: canEdit,
    canEmail: canView,
    canExport: canView,
    canEdit: canEdit,
    canArchive: canEdit,
    onOrderChange,
    onRowChange,
    onRowValueChange,
    onResolveSchedule,
    onDeleteSchedule,
    addSchedule,
    viewResolved,
    publishPdf,
    editCategory,
    archiveCategory,
    sendEmail,
    exportCategory,
    persistCloseCategories,
    isNewSchedule,
    onImportFromExcel,
    getResponsibleOptions,
  };
};

export default useSchedulesCategory;
